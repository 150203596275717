import React, { useState } from "react";
import Accordeon from "../components/FrequentQuestions/Accordeon";

import "../assets/styles/components/FrequentQuestions/frequentQuestions.css";
import RegisterModal from "../components/Modals/RegisterModal";

export default function BasicTabs() {
  const [open, setOpen] = useState(false);

  const handleClickModal = () => {
    setOpen((prev) => !prev);
  };

  return (
    <div className="faqContainer">
      <h2>Preguntas frecuentes</h2>
      <Accordeon />
      <button className="fq-Button" onClick={handleClickModal}>
      <b>EMPIEZA A CONTRATAR</b>
      </button>
      <RegisterModal
        open={open}
        setOpen={setOpen}
        handleClose={handleClickModal}
        contract={true}
        register={false}
      />
    </div>
  );
}
