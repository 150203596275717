import React from "react";
import "../../assets/styles/components/AboutUs/about_us_descripcion.css";

const AboutUsDescription = () => {
  return (
    <div className="description_container_about_us">
      <p className="aboutus_description_title">¿Quiénes somos?</p>
      <p className="aboutus_description_text">
        Somos un grupo de personas e instituciones con experiencia en la
        digitalización de procesos y plataformas que facilitan a las personas innovar y
        sofisticar la forma de manejar sus empresas, aumentar la confianza y disminuir
        los riesgos.
        <br />
        <br />
        Generar Confianza es nuestra razón de ser. Sabemos que la desconfianza evita
        construir mejores relaciones de negocio entre personas y empresas.
        <br />
        <br />
        Estamos conscientes de los altos costos de operar un negocio en Colombia y
        Latinoamérica y que ello genera más informalidad, demoras en tiempos de pago,
        costos ocultos y más riesgos
      </p>
    </div>
  );
};

export default AboutUsDescription;
