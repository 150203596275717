import React from "react";
import "../../assets/styles/components/AboutUs/our_enterprise.css";

import { PopupButton } from "@typeform/embed-react";

const OurEnterprise = () => {
  return (
    <div className="our_enterprise_container">
      <div className="our_enterprise_title_container">
        <p className="our_enterprise_title">Nuestra experiencia</p>
      </div>
      <div className="our_enterprise_content">
        <div className="our_enterprise_content_group">
          <div className="our_enterprise_since_container">
            <p className="our_enterprise_years">14+</p>
            <p className="anios">años</p>
          </div>
          <p className="our_enterprise_description_text">
            Diseño de <b>soluciones tecnológicas</b> que digitalizan y soportan procesos de alta
            transaccionalidad.
          </p>
        </div>
        <div className="our_enterprise_content_group">
          <div className="our_enterprise_since_container">
            <p className="our_enterprise_years">20+</p>
            <p className="anios">años</p>
          </div>
          <p className="our_enterprise_description_text">
            Experiencia con{" "}
            <b>instituciones de registro y de desarrollo empresarial</b>, que
            han entendido la manera de generar confianza para los negocios y
            sofisticación de servicios empresariales.
          </p>
        </div>
      </div>
      <div className="button_container_aboutus">
        <PopupButton
          className="button_aboutus"
          id="N14LmTLt"
          size={80}
          hidden={{
            foo: "Foo Value",
            bar: "Bar Value",
          }}
        >
          CONTÁCTANOS
        </PopupButton>
      </div>
    </div>
  );
};

export default OurEnterprise;
