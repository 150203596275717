import React from 'react';
import { Link } from "react-router-dom";
import '../assets/styles/components/Footer.css';
import muttu_logo from '../assets/static/icons/muttu/muttu_logo.svg';
import Facebook from '../assets/static/icons/social_media/facebook.svg';
import Instagram from '../assets/static/icons/social_media/instagram.svg';
import Youtube from '../assets/static/icons/social_media/youtube.svg';
import { configuration } from '../config';

const Footer = () => {
    return (
        <footer className="footer">
            <div className='footer-content'>
                <div className='footer-muttu-logo'>
                    <Link to="/" className="logoHeader"><img src={muttu_logo} alt="muttu-footer-logo" /></Link>
                </div>
                <div className='footer-muttu-legal'>
                    <div className='footer-legal-links'>
                        <a href={configuration.politica} target="_blank">
                            Política de tratamiento de datos
                        </a>
                        <p>-</p>
                        <a href={configuration.terminos} target="_blank">
                            Términos y condiciones
                        </a>
                    </div>
                </div>
                <div className='footer-text-social'>
                    <div className='social-media'>
                        <a href={configuration.facebookLink} target="_blank">
                            <img src={Facebook} alt="facebook" />
                        </a>
                        <a href={configuration.instagramLink} target="_blank">
                            <img src={Instagram} alt="instagram" />
                        </a>
                        <a href={configuration.youtubeLink} target="_blank">
                            <img src={Youtube} alt="youtube" />
                        </a>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;