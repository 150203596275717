import React from 'react';
import '../../assets/styles/components/AboutUs/about_us_banner.css';
import ImageBannerWeb from '../../assets/static/images/about_us/banner_about_us.png' 
import ImageBannerMobile from '../../assets/static/images/about_us/banner_about_us_mobile.png' 

const AboutUsBanner = () => {
  return (
    <div>
      <div className='aboutus_top_mobile'>
        <img className='ImageBannerMobile' src={ImageBannerMobile} alt="" />
        <div className='text_banner_mobile_container'>
          <p className='text_banner_mobile'>
              Creemos que con la tecnología podemos mejorar las relaciones entre personas, empresas y negocios de una manera significativa
          </p>
        </div>
      </div>
      <div className='ImageBannerWebContainer'>
        <img className='ImageBannerWeb' src={ImageBannerWeb} alt="" />
        <p className='text_banner_web'>
          Creemos que con la tecnología podemos mejorar las relaciones entre personas, empresas y negocios de una manera significativa
        </p>
      </div>
    </div>
  );
}

export default AboutUsBanner;