import React from "react";
import TopContract from "../components/Home/TopContract";
import Steps from "../components/Home/Steps";
import Needs from "../components/Home/Needs";
import Expectations from "../components/Home/Expectations";
import Characteristics from "../components/Home/Characteristics";
import UserThinking from "../components/Home/UserThinking"

function Home() {
  return (
    <div>
      <TopContract />
      <Steps />
      <Needs />
      <Characteristics />
      <Expectations />
      <UserThinking />
    </div>
  );
}

export default Home;
