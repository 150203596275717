import React from "react";
import "../../assets/styles/components/home/expectations.css";

const Expectations = () => {
  return (
    <div className="container_expectations">
      <div className="title_container_expectations">
        <p>¿Qué esperar de nuestro servicio?</p>
      </div>
      <div className="container_card_expectations">
        <div className="card_expectations_row">
          <div className="card_expectations">
            <p className="card_expectations_title">
              Aumentarás la formalidad de tus contratos
            </p>
            <p className="card_expectations_description">
              Disminuyendo sus riesgos, adaptándose a las necesidades de tu
              empresa, y verificando la legalidad e identidad de tu contraparte,
              todo esto por un precio justo.
            </p>
          </div>
          <div className="card_expectations">
            <p className="card_expectations_title">
              Reducirás el tiempo que pasas planificando tus contratos
            </p>
            <p className="card_expectations_description">
              Estableciendo cláusulas justas y agregando alternativas para
              protegerte financieramente.
            </p>
          </div>
        </div>
        <div className="card_expectations_row">
          <div className="card_expectations">
            <div className="container_card_expectations_title">
              <p className="card_expectations_title">
                Anticiparás inteligentemente los riesgos
              </p>
              <div className="container_card_expectations_title_beta">Beta</div>
            </div>
            <p className="card_expectations_description">
              Aquí guardarás trazabilidad segura de los hechos más relevantes de
              tus contratos para que las pequeñas dificultades se gestionen
              amigable y planificadamente, evitando incrementar tus costos
              legales por incumplimientos.
            </p>
          </div>
          <div className="card_expectations">
            <p className="card_expectations_title">
              Construirás experiencia, presentándote con tu historial y encontrando más
              negocios
            </p>
            <p className="card_expectations_description">
              En nuestra solución, tu experiencia contractual será tu carta de presentación para
              hacer más negocios en el ecosistema nacional de contratos inteligentes.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Expectations;
