import React, { useState } from "react";

import "../../assets/styles/components/home/topContract.css";

import mainImageWeb from "../../assets/static/images/home_top_contract/main-card-home.png";
import mainImageMobile from "../../assets/static/images/home_top_contract/main-card-home-mobile.png";
//import mainImage from "../../assets/static/images/home_top_contract/Vídeo_nuevo2.gif";
import RegisterModal from "../Modals/RegisterModal";

const TopContract = () => {
  const [open, setOpen] = useState(false);

  const handleClickModal = () => {
    setOpen((prev) => !prev);
  };

  return (
    <div className="main-card-home">
      <div className="div-img-topContract">
        <img src={mainImageMobile} alt="Muttu"/>
      </div>
      <div className="div-img-topContract-web">
        <img src={mainImageWeb} alt="MuttuWeb"/>
      </div>
      <div className="div-content-topContract">
        <h1 className="title-main-card">
          Agiliza, simplifica y dale seguridad a tu proceso de contratación
        </h1>
        <p className="text-main-card">
          Colabora con tus clientes y proveedores, y encuentra la forma más
          ágil, simple y confiable de proteger a tu empresa ante el riesgo de
          incumplimientos contractuales
        </p>

        <button className="button_contract" onClick={handleClickModal}>
          ELIGE UN CONTRATO
        </button>
      </div>
      <RegisterModal
        open={open}
        setOpen={setOpen}
        handleClose={handleClickModal}
        contract={true}
        register={false}
      />
    </div>
  );
};

export default TopContract;
