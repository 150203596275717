export const configuration = {
    "snaptaskLogin": "https://test.muttu.nuvu.cc/WebData/inicio/0.pub",
    "snaptaskCreate": "https://test.muttu.nuvu.cc/WebData/enrolamiento/crear_solicitud.pub",
    "politica": "/privacidad/PoliticaMuttu.pdf",
    "terminos": "/privacidad/TyCMuttu.pdf",
    "playStore": "https://play.google.com/store/apps/details?id=cc.nuvu.signapp.muttu",
    "appleStore": "https://apps.apple.com/co/app/signapp/id1664222260",
    "facebookLink": "https://www.facebook.com/photo?fbid=122119784192280750&set=a.122119416638280750",
    "instagramLink": "https://www.instagram.com/muttulegaltech/",
    "youtubeLink": "https://www.youtube.com/@redesmuttu",
    "whatsAppLink": "https://api.whatsapp.com/send?phone=+573160281320&text=Buen%20d%C3%ADa%2C%20deseo%20realizar%20una%20consulta%20al%20equipo%20de%20muttu.",
}