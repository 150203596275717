import React /* , { useState } */ from "react";
import PricesBanner from "../components/Prices/PricesBanner";
import PricesContent from "../components/Prices/PricesContent";

function Prices() {
  return (
    <div>
      <PricesBanner />
      <PricesContent />
    </div>
  );
}

export default Prices;
