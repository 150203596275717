import React, { useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import '../../assets/styles/components/home/userThinking.css';
import user_1 from '../../assets/static/images/user_thinking/user_1.png';
import user_2 from '../../assets/static/images/user_thinking/user_2.png';
import user_3 from '../../assets/static/images/user_thinking/user_3.png';
import star from '../../assets/static/icons/user_thinking/star.svg'
import RegisterModal from "../Modals/RegisterModal";

import { Pagination } from "swiper";

const UserThinking = () => {
const [open, setOpen] = useState(false);
 
const handleClickModal = () => {
    setOpen((prev) => !prev);
};

return (
    
    <div className='container_user_thinking'>
        <div className='title_user_thinking'>
            <p>¿Qué piensan los usuarios?</p>
        </div>
        <div className='container_card_user_thinking'>
            <Swiper pagination={true} modules={[Pagination]} className="mySwiper">
                <SwiperSlide>
                <div className='card_user_thinking'>
                    <img src={user_1} alt="" />
                    <p className='card_user_thinking_name'>Fernando Arias</p>
                    <div className='card_user_thinking_stars'>
                        <img src={star} alt="" />
                        <img src={star} alt="" />
                        <img src={star} alt="" />
                        <img src={star} alt="" />
                        <img src={star} alt="" />
                    </div>
                    <p className='card_user_thinking_text'>“Al cerrar un negocio mi mayor preocupación es la <b>minuta del contrato</b>. Con Muttu la tengo lista para el próximo negocio”</p>
                </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className='card_user_thinking'>
                        <img src={user_2} alt="" />
                        <p className='card_user_thinking_name'>Julian Casas</p>
                        <div className='card_user_thinking_stars'>
                            <img src={star} alt="" />
                            <img src={star} alt="" />
                            <img src={star} alt="" />
                            <img src={star} alt="" />
                            <img src={star} alt="" />
                        </div>
                        <p className='card_user_thinking_text'>“Desde hace mucho tiempo esperaba tener una herramienta que me acompañe de principio a fin y que me permita hacer <b>seguimiento a los contratos</b>.”</p>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className='card_user_thinking'>
                        <img src={user_3} alt="" />
                        <p className='card_user_thinking_name'>Ciliana Capera</p>
                        <div className='card_user_thinking_stars'>
                            <img src={star} alt="" />
                            <img src={star} alt="" />
                            <img src={star} alt="" />
                            <img src={star} alt="" />
                            <img src={star} alt="" />
                        </div>
                        <p className='card_user_thinking_text'>“Un servicio que me <b>genera confianza</b>, verificando identidad de con quien voy a hacer negocios y facilitándome los pagos, me ahorra mucho tiempo y dinero”.</p>
                    </div>
                </SwiperSlide>
            </Swiper>
        </div>
        <div className='container_card_user_thinking_web'>
                <div className='card_user_thinking'>
                <img src={user_1} alt="" />
                <p className='card_user_thinking_name'>Fernando Arias</p>
                <div className='card_user_thinking_stars'>
                    <img src={star} alt="" />
                    <img src={star} alt="" />
                    <img src={star} alt="" />
                    <img src={star} alt="" />
                    <img src={star} alt="" />
                </div>
                <p className='card_user_thinking_text'>“Al cerrar un negocio mi mayor preocupación es la <b>minuta del contrato</b>. Con muttu la tengo lista para el proximo negocio”</p>
            </div>
            <div className='card_user_thinking'>
                <img src={user_2} alt="" />
                <p className='card_user_thinking_name'>Julian Casas</p>
                <div className='card_user_thinking_stars'>
                    <img src={star} alt="" />
                    <img src={star} alt="" />
                    <img src={star} alt="" />
                    <img src={star} alt="" />
                    <img src={star} alt="" />
                </div>
                <p className='card_user_thinking_text'>“Desde hace mucho tiempo esperaba tener una herramienta que me acompañe de principio a fin y que me permita hacer <b>seguimiento a los contratos</b>.”</p>
            </div>
            <div className='card_user_thinking'>
                <img src={user_3} alt="" />
                <p className='card_user_thinking_name'>Ciliana Capera</p>
                <div className='card_user_thinking_stars'>
                    <img src={star} alt="" />
                    <img src={star} alt="" />
                    <img src={star} alt="" />
                    <img src={star} alt="" />
                    <img src={star} alt="" />
                </div>
                <p className='card_user_thinking_text'>“Un servicio que me <b>genera confianza</b>, verificando identidad de con quien voy a hacer negocios y facilitandome los pagos, me ahorra mucho tiempo y dinero”.</p>
            </div>
        </div>
        <button className="button_user_thinking" onClick={handleClickModal}>
          ELIGE UN CONTRATO
        </button>
        <RegisterModal
            open={open}
            setOpen={setOpen}
            handleClose={handleClickModal}
            contract={true}
            register={false}
        />
    </div>
  );
}

export default UserThinking;