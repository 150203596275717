import * as React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { configuration } from "../../config";

import androidDownloadImage from "../../assets/static/icons/register_modal/mobile-download-android.png";
import appleDownloadImage from "../../assets/static/icons/register_modal/mobile-download-apple.png";
import qrPlayStore from "../../assets/static/icons/register_modal/qr-playStore.png";
import qrAppStore from "../../assets/static/icons/register_modal/qr-appStore.png";

import "../../assets/styles/components/register_modal.css";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation24.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm":
    {
      borderRadius: "23px",
      padding: "2%",
    },
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(2),
  },
  "& svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium": {
    fill: "var(--second-color)",
  },
  "& .MuiTypography-root": {
    fontFamily: "Poppins, sans-serif",
  },
  "& h2#customized-dialog-title": {
    fontWeight: 700,
    fontSize: "25px",
    lineHeight: "21px",
    textAlign: "center",
  },
  "& button#customized-button:hover": {
    cursor: "pointer",
    backgroundColor: "var(--first-color)",
    color: "var(--third-color)",
  },
  "& p.MuiTypography-root.MuiTypography-body1.MuiTypography-gutterBottom": {
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "29px",
    textAlign: "center",
  },
  "& .MuiDialogActions-root.MuiDialogActions-spacing": {
    alignSelf: "center",
  },
  "& button.MuiButtonBase-root.MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-sizeMedium.MuiButton-textSizeMedium":
    {
      fontFamily: "Poppins, sans-serif",
      textTransform: "capitalize",
      fontWeight: 600,
      fontSize: "21px",
      backgroundColor: "var(--third-color)",
      color: "var(--first-color)",
      width: "230px",
      borderRadius: "23px",
      boxShadow: "0px 6px 5px -1px rgba(0, 0, 0, 0.54)",
    },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function RegisterModal({
  open,
  handleClose,
  contract,
  register,
}) {
  const [isStep1, setIsStep1] = React.useState(false);
  const [isStep2, setIsStep2] = React.useState(false);
  const [isChoosingContract, setIsChoosingContract] = React.useState(contract);
  const [isRegistering, setIsRegistering] = React.useState(register);

  const getTitle = () => {
    if (isChoosingContract) {
      return "Elige un contrato";
    }
    if (isRegistering) {
      return "Registro";
    }
    if (isStep1) {
      return "Paso 1";
    }
    if (isStep2) {
      return "Paso 2";
    }
  };

  const getBody = () => {
    if (isChoosingContract || isRegistering) {
      return (
        <Typography
          gutterBottom
          color="var(--second-color)"
          margin="15px 0 0 0"
        >
          Estás a solo 3 pasos de obtener nuestros servicios, regístrate para
          continuar con el proceso.
        </Typography>
      );
    }
    if (isStep1) {
      return (
        <>
          <Typography
            gutterBottom
            color="var(--second-color)"
            margin="0 0 15px 0"
          >
            Descarga nuestra aplicación <b>SignApp</b>, con ella podremos
            generar la identificación biométrica de las personas que participan
            en tus contratos, dándote la máxima confianza y seguridad.
          </Typography>
        </>
      );
    }
    if (isStep2) {
      return (
        <>
          <Typography
            gutterBottom
            color="var(--second-color)"
            margin="15px 0 0 0"
          >
            Completa tus datos y obtén el Token de seguridad que la aplicación
            SignApp te generó.
          </Typography>
          <Typography
            gutterBottom
            color="var(--second-color)"
            margin="15px 0 0 0"
            id="info"
          >
            Estamos trabajando para que puedas llevar tu Muttu a todos lados en
            versión Móvil. Por ahora para seguir vinculándote ingresa a través
            del navegador de tu pc.
          </Typography>
        </>
      );
    }
  };

  const changeStep = () => {
    if (isRegistering) {
      setIsRegistering(false);
      setIsStep1(true);
    }
    if (isChoosingContract) {
      setIsChoosingContract(false);
      setIsStep1(true);
    }
    if (isStep1) {
      setIsStep1(false);
      setIsStep2(true);
    }
    if (isStep2) {
      setIsStep2(false);
      setIsChoosingContract(contract);
      setIsRegistering(register);
      handleClose();
      window.open(
        configuration.snaptaskCreate,
        "_blank",
        "noopener,noreferrer"
      );
    }
  };

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={() => {
            setIsStep1(false);
            setIsStep2(false);
            setIsChoosingContract(contract);
            setIsRegistering(register);
            handleClose();
          }}
        >
          {getTitle()}
        </BootstrapDialogTitle>
        <DialogContent>
          {getBody()}
          {isStep1 && (
            <div className="downloadApp">
              <div className="downloadApp-sourse">
                <a href={configuration.playStore}>
                  <img src={androidDownloadImage} alt="Descarga Android" />
                  <img
                    src={qrPlayStore}
                    alt="Qr descarga android"
                    className="desktop-part"
                  />
                  <p className="desktop-part">Play Store</p>
                </a>
              </div>
              <div className="downloadApp-sourse">
                <a href={configuration.appleStore}>
                  <img src={appleDownloadImage} alt="Descarga ios" />
                  <img
                    src={qrAppStore}
                    alt="Qr descarga ios"
                    className="desktop-part"
                  />
                  <p className="desktop-part">App Store</p>
                </a>
              </div>
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={changeStep} id="customized-button">
            CONTINUAR
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}
