import React, { useState } from 'react';
import '../../assets/styles/components/prices/prices_banner.css';
import PriceImageBannerWeb from '../../assets/static/images/prices/bannerPricesDesk.png'
import PriceImageBannerMobile from '../../assets/static/images/prices/bannerPrices.png'
import FormContactModal from "../Modals/RDStation/FormContactModal";

const PricesBanner = () => {

  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div>
      <div className='prices_top_mobile'>
        <img className='PriceImageBannerMobile' src={PriceImageBannerMobile} alt="" />
        <div className='price_text_banner_mobile_container'>
          <p className='price_text_banner_mobile'>
            Contratos seguros y de bajo costo, para lograr que más personas accedan de una forma fácil y sencilla a la protección que ofrecen los contratos legales.
          </p>
          <div className='container_button_prices_mobile'>
            <button className="button_prices_mobile" onClick={openModal}>
              PRUEBA GRATIS
            </button>
          </div>
        </div>
      </div>
      <div className='PriceImageBannerWebContainer'>
        <img className='PriceImageBannerWeb' src={PriceImageBannerWeb} alt="" />
        <div className="div-content-topPrices">
          <p className="text-main-card-prices">
            Contratos seguros y de bajo costo, para lograr que más personas accedan de una forma fácil y sencilla a la protección que ofrecen los contratos legales.
          </p>
          <button className="button_prices" onClick={openModal}>
            PRUEBA GRATIS
          </button>
          <FormContactModal isOpen={isModalOpen} closeModal={closeModal} />
        </div>
      </div>
    </div>
  );
}

export default PricesBanner;