import * as React from "react";
import CharacteristicsCards from "./CharacteristicsCards";
import CharacteristicsSlider from "./CharacteristicsSlider";

import "../../assets/styles/components/home/characteristic.css";

const Characteristics = () => {
  return (
    <div className="characteristicsContainer">
      <div className="characteristicsTitleContainer">
        <h1>
          Tenemos todo lo que necesitas para facilitar la gestión de contratos
        </h1>
      </div>
      <div className="cardDesk">
        <CharacteristicsCards />
      </div>
      <div className="sliderMobile">
        <CharacteristicsSlider />
      </div>
    </div>
  );
};

export default Characteristics;
