import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import FormContact from './FormContact'
import '../../../assets/styles/components/RDStation/formModalContact.css';
import CloseModal from "../../../assets/static/icons/contact_modal/close-modal.svg"

export default function ResponsiveDialog({ isOpen, closeModal }) {

  return (
    <div>
      <Dialog
        open={isOpen}
        onClose={closeModal}
      >
        <DialogContent className='modal-contact-container'>
          <button className='modal-contact-button' onClick={closeModal}><img src={CloseModal} alt="Cerrar modal" /></button>
          <FormContact />
        </DialogContent>
      </Dialog>
    </div>

  );
}