import * as React from "react";
import { Link } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import MenuItem from "@mui/material/MenuItem";
import muttu_logo from "../assets/static/icons/muttu/muttu_logo.svg";
import "../assets/styles/components/Header.css";
import RegisterModal from "./Modals/RegisterModal";
import { configuration } from "../config";

const settings = ["Profile", "Account", "Dashboard", "Logout"];

const Header = () => {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [open, setOpen] = React.useState(false);

  const handleClickModal = () => {
    setOpen((prev) => !prev);
  };

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <AppBar position="static">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Link to="/" className="logoHeader"><img src={muttu_logo} alt="" /></Link>


          <Box
            sx={{
              flexGrow: 1,
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <IconButton
              onClick={handleOpenNavMenu}
              color="inherit"
              sx={{
                display: { xs: "flex", md: "none" },
              }}
            >
              <MenuIcon />
            </IconButton>

            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                display: "flex",
                vertical: "bottom",
                top: "56px",
                right: "0",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{ width: "100%", display: { xs: "block", md: "none" } }}
            >
              <MenuItem onClick={handleCloseNavMenu}>
                <Link to="/">Home</Link>
                <Link to="/aboutus">Quienes somos</Link>
                <Link to="/prices">Precios</Link>
                <Link to="/PreguntasFrecuentes">Preguntas frecuentes</Link>
                <p id={"pToHeader"} onClick={handleClickModal}>
                  Registrarme
                </p>
                <div className="button_container_header">
                  <button
                    className="button_header"
                    onClick={() => {
                      window.open(
                        configuration.snaptaskLogin,
                        "_blank",
                        "noopener,noreferrer"
                      );
                    }}
                  >
                    Iniciar sesión
                  </button>
                </div>
              </MenuItem>
            </Menu>
          </Box>
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "none", md: "flex", justifyContent: "flex-end" },
            }}
          >
            <Link to="/">Home</Link>
            <Link to="/aboutus">Quienes somos</Link>
            <Link to="/prices">Precios</Link>
            <Link to="/PreguntasFrecuentes">Preguntas frecuentes</Link>
            <p id={"pToHeader"} onClick={handleClickModal}>
              Registrarme
            </p>
            <div className="button_container_header">
              <button
                className="button_header"
                onClick={() => {
                  window.open(
                    configuration.ssnaptaskLogin,
                    "_blank",
                    "noopener,noreferrer"
                  );
                }}
              >
                Iniciar sesión
              </button>
            </div>
          </Box>
          <RegisterModal
            open={open}
            setOpen={setOpen}
            handleClose={handleClickModal}
            contract={false}
            register={true}
          />
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default Header;
