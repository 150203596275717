import * as React from "react";

import agilidad from "../../assets/static/icons/caracteristicas/Agilidad.svg";
import experiencia from "../../assets/static/icons/caracteristicas/Experiencia.svg";
import gestion from "../../assets/static/icons/caracteristicas/Gestion.svg";
import proteccion from "../../assets/static/icons/caracteristicas/Proteccion.svg";

import "../../assets/styles/components/home/characteristicsCards.css";

const CharacteristicsCards = () => {
  return (
    <div className="characteristicCardContainer">
      <div className="characteristicCard left">
        <div className="cardTittle">
          <img src={agilidad} alt="Ícono Agilidad" />
          <h2>Agilidad</h2>
        </div>
        <p>
          Selecciona contratos predeterminados generados por plantillas
          inteligentes, sin intermediación de abogados
        </p>
      </div>
      <div className="characteristicCard">
        <span className="rigthTag">Beta</span>
        <div className="cardTittle">
          <img src={proteccion} alt="Ícono Protección" />
          <h2>Protección</h2>
        </div>
        <p>
          Registro Confiable y Seguro de tus acuerdos. Contamos con tecnología
          Blockchain para algunos contratos.
        </p>
      </div>
      <div className="characteristicCard">
        <span className="leftTag">Próximamente</span>
        <div className="cardTittle">
          <img src={gestion} alt="Ícono Gestión" />
          <h2>Gestión</h2>
        </div>
        <p>
          Ahorros directos, optimización de pagos y recaudos producto de tus
          contratos
        </p>
      </div>
      <div className="characteristicCard rigth">
        <div className="cardTittle">
          <img src={experiencia} alt="Ícono Experiencia" />
          <h2>Experiencia</h2>
        </div>
        <p>
          Maneja tu historial de contratos, hazle seguimiento, construye
          experiencia y reputación como contratante o contratista
        </p>
      </div>
    </div>
  );
};

export default CharacteristicsCards;
