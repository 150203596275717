import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";

import agilidad from "../../assets/static/icons/caracteristicas/Agilidad.svg";
import experiencia from "../../assets/static/icons/caracteristicas/Experiencia.svg";
import gestion from "../../assets/static/icons/caracteristicas/Gestion.svg";
import proteccion from "../../assets/static/icons/caracteristicas/Proteccion.svg";

import "../../assets/styles/components/home/characteristicsSlider.css";

export default function CharacteristicsSlider() {
  return (
    <div className="carousel-wrapper">
      <Carousel
        showStatus={false}
        showThumbs={false}
        infiniteLoop={true}
        showArrows={false}
      >
        <div>
          <div className="characteristicCard-slider">
            <div className="cardTittle-slider">
              <img src={agilidad} alt="Ícono Agilidad" />
              <h2>Agilidad</h2>
            </div>
            <p>
              Selecciona contratos predeterminados generados por plantillas
              inteligentes, sin intermediación de abogados
            </p>
          </div>
        </div>
        <div>
          <div className="characteristicCard-slider_tag">
            <div className="characteristicCard_tag">
              <span className="rigthTag">Beta</span>
            </div>
            <div className="characteristicCard_content">
              <div className="cardTittle-slider">
                <img src={proteccion} alt="Ícono Protección" />
                <h2>Protección</h2>
              </div>
              <p>
                Registro Confiable y Seguro de tus acuerdos. Contamos con
                tecnología Blockchain para algunos contratos.
              </p>
            </div>
          </div>
        </div>
        <div className="characteristicCard-slider_tag">
            <div className="characteristicCard_tag">
              <span className="leftTag">Próximamente</span>
            </div>
            <div className="characteristicCard_content">
              <div className="cardTittle-slider">
                <img src={proteccion} alt="Ícono Protección" />
                <h2>Protección</h2>
              </div>
              <p>
                Registro Confiable y Seguro de tus acuerdos. Contamos con
                tecnología Blockchain para algunos contratos.
              </p>
            </div>
          </div>
        <div>
          <div className="characteristicCard-slider">
            <div className="cardTittle-slider">
              <img src={experiencia} alt="Ícono Experiencia" />
              <h2>Experiencia</h2>
            </div>
            <p>
              Maneja tu historial de contratos, hazle seguimiento, construye
              experiencia y reputación como contratante o contratista
            </p>
          </div>
        </div>
      </Carousel>
    </div>
  );
}
