import React from 'react';
import '../../assets/styles/components/AboutUs/vision.css';

const Vision = () => {
  return (
    <div>
        <div className='vision_container'>
            <p className='vision_title'>Visión</p>
              <p className='vision_text'>Ser la solución preferida en Latinoamérica para la formalización de acuerdos mediante contratos inteligentes de las pequeñas-medianas empresas, las
              personas naturales y profesionales independientes. Ayudar a disminuir los
              problemas legales derivados de malos procesos de contratación o de la
              ausencia de formalidad contractual. Y que en cada negocio, por pequeño que
              sea, estemos presentes para brindar herramientas que generen confianza y
              facilidad para formalizarlo.</p>
        </div>
    </div>
  );
}

export default Vision;