import React from "react";
import AboutUsBanner from "../components/AboutUs/AboutUsBanner";
import AboutUsDescription from "../components/AboutUs/AboutUsDescription";
import Vision from "../components/AboutUs/Vision";
import OurEnterprise from "../components/AboutUs/OurEnterprise";

function AboutUs() {
  return (
    <div>
      <AboutUsBanner />
      <AboutUsDescription />
      <Vision />
      <OurEnterprise /> 
    </div>
  );
}

export default AboutUs;