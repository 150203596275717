import "../../assets/styles/components/home/steps.css";
import Puntero from "../../assets/static/icons/sencillos_pasos/click.svg";
import Ajustes from "../../assets/static/icons/sencillos_pasos/ajustes.svg";
import Firma from "../../assets/static/icons/sencillos_pasos/firma.svg";
import Notas from "../../assets/static/icons/sencillos_pasos/notas.svg";

const Pasos = () => {
  return (
    <div className="container">
      <div className="container_title">
        <p>Sencillos pasos para tu primer contrato</p>
      </div>
      <div className="container_text">
        <p>
          Usar nuestro servicio es fácil y te permite aprovechar mejor tus contratos
        </p>
      </div>
      <div className="container_steps">
        <div className="container_steps_items">
          <div className="step_item">
            <img src={Puntero} alt="logo-Puntero" />
            <p>Selecciona una plantilla</p>
          </div>
          <div className="number_item">
            <p>1</p>
          </div>
        </div>
        <div className="container_steps_items">
          <div className="step_item">
            <img src={Ajustes} alt="logo-Ajustes" />
            <p>Configura el contrato</p>
          </div>
          <div className="number_item">
            <p>2</p>
          </div>
        </div>
        <div className="container_steps_items">
          <div className="step_item">
            <img src={Firma} alt="logo-Firma" />
            <p>Firma y envía</p>
          </div>
          <div className="number_item">
            <p>3</p>
          </div>
        </div>
        <div className="container_steps_items">
          <div className="step_item">
            <img src={Notas} alt="logo-Notas" />
            <p>Dale seguimiento</p>
          </div>
          <div className="number_item">
            <p>4</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pasos;
