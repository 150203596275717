import React, { useState } from "react";
import "../../assets/styles/components/prices/prices_content.css";
import FormContactModal from "../Modals/RDStation/FormContactModal";

function PricesContent() {

  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  /* const [open, setOpen] = useState(false);

  const handleClickModal = () => {
    setOpen((prev) => !prev);
  }; */
  return (
    <div className="pricesContainer">
      <p className="firstText">
        “Sin mensualidades, ni costos extras, paga por cada contrato que
        registres”
      </p>
      <div className="initialCard-prices">
        <p>Con un solo pago tendrás acceso a estos beneficios:</p>
      </div>
      <div className="listCard-prices">
        <ul className="listCard-prices-items">
          <li>Acceso a plantillas según el tipo de negocio</li>
          <li>Verificación de identidades, con tecnología de autenticación biométrica</li>
          <li>Gestión y Registro Seguro del Contrato</li>
          <li>Dashboards de control y administración de tus contratos</li>
          <li>Opción de Fiducia de bajo monto<span className="leftTagPrice">Próximamente</span></li>
          <li>Optimización de pagos<span className="leftTagPrice">Próximamente</span></li>
          <li>Validación en listas de control<span className="leftTagPrice">Próximamente</span></li>
        </ul>
      </div>
      <button className="button_prices" onClick={openModal}>
        PRUEBA GRATIS
      </button>
      <FormContactModal isOpen={isModalOpen} closeModal={closeModal} />
      {/* <button onClick={handleClickModal} className="button">
        Me interesa y quiero saber el costo
      </button>
      <ContactModal
        open={open}
        setOpen={setOpen}
        handleClose={handleClickModal}
        contact={false}
        prices={true}
      /> */}
    </div>
  );
}

export default PricesContent;
