import * as React from "react";
import Fab from "@mui/material/Fab";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import "../assets/styles/components/whatsAppButton.css";
import { configuration } from "../config";

const WhatsAppButton = () => {
  return (
    <Fab
      color="success"
      aria-label="edit"
      id={"button-WhatsApp"}
      onClick={() => {
        window.open(
          configuration.whatsAppLink,
          "_blank",
          "noopener,noreferrer"
        );
      }}
    >
      <WhatsAppIcon />
    </Fab>
  );
};

export default WhatsAppButton;
