import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import "../../assets/styles/components/FrequentQuestions/accordeon.css";

const Accordeon = () => {
  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "transparent" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="Accordeon"
          TabIndicatorProps={{ style: { background: "#D4F400" } }}
        >
          <Tab label="General" {...a11yProps(0)} />
          <Tab label="Seguridad y cuentas" {...a11yProps(1)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <div>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>
                ¿Cómo puedo sacar mayor provecho a <b>Muttu</b>?{" "}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Usando Muttu para todos tus contratos les sacas mayor provecho. Construyes experiencia
                e historial de contratación y de esta manera puedes demostrar mayor confianza ante tus
                clientes, proveedores y otras entidades.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>
                ¿Cómo empezar a utilizar <b>Muttu</b>?{" "}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Realizando tu registro, valida tu identidad, selecciona el contrato que quieres personalizar.
                Muttu te ayuda a enviárselo a tu cliente o proveedor para que realice su parte y listo.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>
                ¿Quiénes pueden utilizar <b>Muttu</b>?{" "}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Desde Freelancers, hasta empresas y sin importar el monto del contrato. Muttu es para todos
                los que quieren confianza y formalidad en sus contratos.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>
                ¿Puedo pedir capacitaciones para manejar y aprovechar al máximo la plataforma?{" "}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Claro que sí!, Muttu es muy fácil de usar. Regístrate en nuestro Newsletter y
                recibirás tips y recomendaciones de cómo sacarle el mejor provecho a Muttu.
              </Typography>
            </AccordionDetails>
          </Accordion>
        </div>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <div>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>
                ¿Está segura mi información en <b>Muttu</b>?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Para Muttu tu información y la de todos es una prioridad. Con nuestros protocolos de
                seguridad Grado Bancario y Blockchain tu información estará segura y disponible solo para ti.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>¿Sus documentos tienen alguna garantía?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                En Muttu te daremos plantillas y sugerencias de la mejor forma de construir tu contrato.
                Tu lo adaptas a tus necesidades y nosotros lo cuidamos tal y como lo acuerdas con tu
                contraparte y te garantizamos que nadie, salvo ustedes lo puedan cambiar.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>
                ¿Es el almacenamiento seguro en <b>Muttu</b>?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Para Muttu tu información y la de todos es una prioridad. Con nuestros protocolos de
                seguridad Grado Bancario y Blockchain tu información estará segura y disponible solo para ti.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>
                Perdí mi Usuario y/o correo electrónico
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Puedes darle a la opción "Olvidé mi usuario y/o Contraseña"  en la pantalla de inicio de
                sesión y generar una nueva
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>No puedo acceder a mi cuenta</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
              Puedes generar una nueva clave en la pantalla de inicio de sesión, pero si
              no puedes hacer esto, puedes escribirnos al contacto@muttu.com.co nos
              cuentas tu problema y nuestros asesores se comunicarán contigo para
              solucionarlo.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>¿Qué validez tiene una firma digital en <b>Muttu</b>?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                100% Validez jurídica.
                Cumplimiento de la Ley 527 de 1999 y el Decreto 2364 de 2012 sobre la firma electrónica.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>
                ¿Puede alguien modificar documentos digitales en <b>Muttu</b>?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Los documentos se realizan directamente en la plataforma, por lo cual no hay necesidad de
                adjuntar contratos desde tu computador. En el caso de que sea un contrato realizado y
                firmado en Muttu solo será posible mediante una adenda al contrato (un otrosí) de mutuo
                acuerdo, de lo contrario los documentos no serán modificables, esto con el objetivo de
                garantizar su inmutabilidad.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>
                ¿Cómo puedo pagar por los servicios de <b>Muttu</b>?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                No te preocupes por eso, en este momento nuestros Usuarios Beta pueden solicitar un
                Acceso Gratuito y gestionar sus contratos en Muttu.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>
                ¿Cómo saber el estado de un documento enviado para firmar o campaña?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Cada vez que hay un proceso terminado como un documento enviado para firmar, se enviará
                un mensaje al correo registrado de la contraparte del contrato, con lo cual siempre habrá
                notificaciones que permitan mayor visibilidad de los procesos.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>
                ¿Necesito un Token cada vez que tengo que firmar o realizar cambios en la información que
                me solicita <b>Muttu</b>?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Sí, mediante nuestra aplicación SignApp, realizas tu registro por primer y única vez. Desde ahí,
                podrás solicitar tu Token para crear contratos y firmarlos. De esta manera garantizamos que
                solamente tú, o quien tú delegues, sean quien modifique y cree contratos.
              </Typography>
            </AccordionDetails>
          </Accordion>
        </div>
      </TabPanel>
    </Box>
  );
};

export default Accordeon;
