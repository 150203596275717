import React from "react";
import "./assets/styles/App.css";
import { Routes, Route } from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Home from "./pages/Home";
import WhatsAppButton from "./components/WhatsAppButton";
import Prices from "./pages/Prices";
import FrequentQuestions from "./pages/FrequentQuestions";
import AboutUs from "./pages/AboutUs";
import PageView from "./components/PageView/PageView.jsx"

function App() {
  return (
    <>
      <PageView />
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/prices" element={<Prices />} />
        <Route path="/PreguntasFrecuentes" element={<FrequentQuestions />} />
        <Route path="/aboutus" element={<AboutUs/>}/>
      </Routes>
      <WhatsAppButton />
      <Footer />
    </>
  );
}

export default App;
