import React /* , { useState } */ from "react";
import "../../assets/styles/components/home/needs.css";
import client_1 from "../../assets/static/images/home_needs/client_1.png";
import client_2 from "../../assets/static/images/home_needs/client_2.png";
import client_3 from "../../assets/static/images/home_needs/client_3.png";
import client_4 from "../../assets/static/images/home_needs/client_4.png";
/* import muttu_gray_logo from "../../assets/static/icons/muttu/muttu_logo_gris.svg";
import point_gray from "../../assets/static/icons/muttu/icono_punto.svg"; */
//import ContactModal from "../Modals/ContactModal";
import { PopupButton } from "@typeform/embed-react";

const Necesidades = () => {
  /* const [open, setOpen] = useState(false);

  const handleClickModal = () => {
    setOpen((prev) => !prev);
  }; */
  return (
    <div className="container_needs">
      <div className="title_container_needs">
        <p>Tu empresa es muy importante y entendemos tus necesidades</p>
      </div>
      <div className="container_text_needs">
        <p>
          Muttu te ayuda a formalizar y gestionar tus contratos de negocio en un solo
          sitio. Así podrás reducir riesgos y obtener toda la información que necesitas
          para tener más confianza en tus negocios
        </p>
      </div>
      <div className="container_client_1">
        <div className="imagen_cliente">
          <img src={client_1} alt="" />
          {/* <div className="bar_complement">
            <div className="container_logo_muttu_client">
              <img src={muttu_gray_logo} alt="" />
            </div>
            <div>
              <p>Claudia - Profesional independiente</p>
            </div>
            <div className="container_point_client">
              <img src={point_gray} alt="" />
            </div>
          </div> */}
        </div>
        <div className="container_client_text">
          <p className="client_title">Evita Costos Ocultos</p>
          <p className="client_text">
            Protégete desde el inicio de tus acuerdos y no gastes extra en
            abogados
          </p>
        </div>
      </div>
      <div className="container_client_2">
        <div className="imagen_cliente">
          <img src={client_2} alt="" />
          {/* <div className="bar_complement">
            <div className="container_logo_muttu_client">
              <img src={muttu_gray_logo} alt="" />
            </div>
            <div>
              <p>Don Pedro - Comerciante</p>
            </div>
            <div className="container_point_client">
              <img src={point_gray} alt="" />
            </div>
          </div> */}
        </div>
        <div className="container_client_text">
          <p className="client_title">Contrata con confianza</p>
          <p className="client_text">
            Cuando realices un contrato, déjanos a nosotros la tarea de
            verificar la identidad de tu contraparte para que sientas más
            confianza
          </p>
        </div>
      </div>
      <div className="container_client_3">
        <div className="imagen_cliente">
          <img src={client_3} alt="" />
          {/* <div className="bar_complement">
            <div className="container_logo_muttu_client">
              <img src={muttu_gray_logo} alt="" />
            </div>
            <div>
              <p>Camilo - Jefe de empresa de metal-mecánica</p>
            </div>
            <div className="container_point_client">
              <img src={point_gray} alt="" />
            </div>
          </div> */}
        </div>
        <div className="container_client_text">
          <p className="client_title">
            Reduce costos y evita disputas jurídicas tediosas
          </p>
          <p className="client_text">
            Evita disputas jurídicas creando contratos inteligentes y
            almacenándolos de manera segura, así puedes consultarlo en cualquier
            momento, en cualquier lugar.
          </p>
        </div>
      </div>
      <div className="container_client_4">
        <div className="imagen_cliente">
          <img src={client_4} alt="" />
          {/*<div className="bar_complement">
            <div className="container_logo_muttu_client">
              <img src={muttu_gray_logo} alt="" />
            </div>
            <div>
              <p>Felipe - distribuidor de supermercados</p>
            </div>
            <div className="container_point_client">
              <img src={point_gray} alt="" />
            </div>
          </div> */}
        </div>
        <div className="container_client_text">
          <p className="client_title">Siente respaldo y seguridad</p>
          <p className="client_text">
            No importa si el contrato es pequeño, con nuestra solución los
            contratos pequeños son lo más importante
          </p>
        </div>
      </div>
      <div className="button_container">
        <PopupButton
          className="button_needs"
          id="N14LmTLt"
          size={80}
          hidden={{
            foo: "Foo Value",
            bar: "Bar Value",
          }}
        >
          CONTÁCTANOS
        </PopupButton>
        {/*<button className="button" onClick={handleClickModal}>
          Contactenos
        </button>
         <ContactModal
          open={open}
          setOpen={setOpen}
          handleClose={handleClickModal}
          contact={true}
          prices={false}
        /> */}
      </div>
    </div>
  );
};

export default Necesidades;
