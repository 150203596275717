import React, { Component } from 'react';

class FormContactModal extends Component {
  componentDidMount() {
    if (window.RDStationForms) {
      new window.RDStationForms('prueba-gratis-8a3c5e218e4ed4e5506a', 'null').createForm();
    }
  }

  render() {
    return (
      <div>
        <div role="main" id="prueba-gratis-8a3c5e218e4ed4e5506a"></div>
      </div>

    );
  }
}

export default FormContactModal;